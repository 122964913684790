/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Palette theme page to create custom palette colors.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 24/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { alpha } from '@mui/material';

// ----------------------------------------------------------------------

/* SETUP COLORS */
const PRIMARY = {
  light: '#FE9C66',
  main: '#F05A1E',
  dark: '#EB5400'
};
const SECONDARY = {
  light: '#AAF27F',
  main: '#1F6020',
  dark: '#229A16'
};
const INFO = {
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7'
};
const SUCCESS = {
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16'
};
const WARNING = {
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103'
};
const ERROR = {
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136'
};

const GREY = {
  100: '#F2F2F2',
  200: '#DEE1E6',
  300: '#C4C4C4',
  400: '#B2B2B2',
  500: '#BABABA',
  600: '#9D9D9D',
  700: '#636363',
  800: '#3D3D3D',
  900: '#171818',
  500_8: alpha('#BABABA', 0.08),
  500_12: alpha('#BABABA', 0.12),
  500_16: alpha('#BABABA', 0.16),
  500_24: alpha('#BABABA', 0.24),
  500_32: alpha('#BABABA', 0.32),
  500_48: alpha('#BABABA', 0.48),
  500_56: alpha('#BABABA', 0.56),
  500_80: alpha('#BABABA', 0.8)
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: '#fff' },
  warning: { ...WARNING, contrastText: '#fff' },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  divider: GREY[300],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500],
    disabledBackground: GREY[300],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

const palette = {
  ...COMMON,
  text: { primary: '#000000', secondary: GREY[500], disabled: GREY[300] },
  background: { paper: GREY[100], default: '#FFFFFF' },
  action: { active: GREY[600], ...COMMON.action }
};

export default palette;
