/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description ComponentsOverrides holds all imported components
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 24/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { merge } from 'lodash';

/* Local Imports */
import Avatar from './Avatar';
import Backdrop from './Backdrop';
import Button from './Button';
import ControlLabel from './ControlLabel';
import Dialog from './Dialog';
import Divider from './Divider';
import IconButton from './IconButton';
import Input from './Input';
import Link from './Link';
import LoadingButton from './LoadingButton';
import Paper from './Paper';
import Typography from './Typography';

// ----------------------------------------------------------------------

/**
 * Allows all imported components to use theme by passing as props, merging and making into single component.
 * @component
 * @param theme - passed as props to the imported components
 * @returns single component containing all imported components
 */
export default function ComponentsOverrides(theme) {
  /* Output */
  return merge(
    Avatar(theme),
    Backdrop(theme),
    Button(theme),
    ControlLabel(theme),
    Dialog(theme),
    Divider(),
    IconButton(),
    Input(theme),
    Link(theme),
    LoadingButton(theme),
    Paper(),
    Typography(theme)
  );
}
