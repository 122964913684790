/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Page to create styles for auth layout.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 26/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

export default {
  wrapperStyle: {
    backgroundImage: 'url(/assets/images/background.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 'right',
    backgroundPositionY: 'bottom',
    height: '100vh',
    position: 'relative',
    overflow: 'auto'
  },
  mainStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh'
  }
};
