/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Main app component to enter in project.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 24/Apr/2024
 * Test Case Ref:
 */
// ----------------------------------------------------------------------

/* Imports */
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

/* Relative Imports */
import ThemeConfig from 'theme';
import Routing from 'routes';
import { SessionProvider } from 'context/SessionContext';
import ScrollToTop from 'components/ScrollToTop';
import NotistackProvider from 'components/NotistackProvider';

// ----------------------------------------------------------------------

/**
 * App component to to set all the higher level components and routes.
 *
 * @component
 * @return {JSX.Element}
 */
function App() {
  /* Output */
  return (
    <ThemeConfig>
      <NotistackProvider>
        <SessionProvider>
          <Router>
            <ScrollToTop />
            <Routing />
          </Router>
        </SessionProvider>
      </NotistackProvider>
    </ThemeConfig>
  );
}

export default App;
