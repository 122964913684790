/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Page to create styles for notistack provider(snackbar) component.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 25/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

export default {
  rootStyle: (theme) => ({
    height: '100%',
    width: '100%',
    '& .notistack-SnackbarContainer': {
      maxWidth: 320,
      '& .notistack-MuiContent': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        borderRadius: 0.75,
        padding: theme.spacing(1, 2, 1, 1.25),
        flexWrap: 'nowrap',
        '&.notistack-MuiContent-success': {
          borderLeft: `8px solid ${theme.palette.success.main}`
        },
        '&.notistack-MuiContent-error': {
          borderLeft: `8px solid ${theme.palette.error.main}`
        },
        '&.notistack-MuiContent-warning': {
          borderLeft: `8px solid ${theme.palette.warning.main}`
        },
        '&.notistack-MuiContent-info': {
          borderLeft: `8px solid ${theme.palette.info.main}`
        },
        '&:first-of-type': {
          flex: 1
        }
      }
    }
  }),
  iconBox: {
    mr: 1.25,
    display: 'flex',
    fontSize: 16
  }
};
