/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description IconButton component to override default Mui IconButton's style.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 24/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/**
 * IconButton contains the styles to override default Mui IconButton and it's children's styles.
 *
 * @component
 * @returns Add-on styles for MuiIconButton
 */
export default function IconButton() {
  /* Output */
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
          '&:hover': {
            background: 'none'
          }
        }
      }
    }
  };
}
