/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Page to define all the paths.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 25/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Root Pages */
export const PAGES = {
  root: '/',
  registerSIM: {
    relativePath: 'register-sim',
    absolutePath: '/register-sim'
  },
  dreamTeam: {
    relativePath: 'dream-team',
    absolutePath: '/dream-team'
  },
  ricaTraining: {
    relativePath: 'rica-training',
    absolutePath: '/rica-training'
  },
  rica: {
    relativePath: 'rica',
    absolutePath: '/rica'
  },
  ricaPort: {
    relativePath: 'rica-port',
    absolutePath: '/rica-port'
  },
  registerAgent: {
    relativePath: 'register-agent',
    absolutePath: '/register-agent'
  },
  resetPassword: {
    relativePath: 'password',
    absolutePath: '/password'
  }
};
