/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description ControlLabel component to override default Mui ControlLabel's style.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 24/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/**
 * ControlLabel contains the styles to override default Mui ControlLabel and it's children's styles.
 *
 * @component
 * @param theme - global theme object to provide colors, fonts, spacing, shadows etc.
 * @returns Add-on styles for MuiControlLabel
 */
export default function ControlLabel(theme) {
  /* Output */
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          ...theme.typography.subtitle2
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: theme.spacing(1),
          marginLeft: theme.spacing(0)
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          ...theme.typography.subtitle2,
          color: theme.palette.text.primary,
          '&.Mui-focused': {
            color: theme.palette.text.primary
          }
        },
        asterisk: {
          color: theme.palette.text.primary,
          '&.Mui-error': {
            color: theme.palette.error.main
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          left: '-10px'
        }
      }
    }
  };
}
