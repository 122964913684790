/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Link component to override default Mui Link's style.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 24/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/**
 * Link contains the styles to override default Mui Link and it's children's styles.
 *
 * @component
 * @returns Add-on styles for MuiLink
 */
export default function Link(theme) {
  /* Output */
  return {
    MuiLink: {
      defaultProps: {
        variant: 'h6'
      },
      styleOverrides: {}
    }
  };
}
