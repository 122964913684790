/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Input component to override default Mui Input's style.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 24/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/**
 * Input contains the styles to override default Mui Input and it's children's styles.
 *
 * @component
 * @param theme - global theme object to provide colors, fonts, spacing, shadows etc.
 * @returns Add-on styles for MuiInput
 */
export default function Input(theme) {
  /* Output */
  return {
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...theme.typography.body2,
          overflow: 'hidden',
          '& .MuiInputAdornment-root': {
            '& .MuiIconButton-root': {
              marginRight: 0
            }
          }
        },
        inputSizeSmall: {
          height: 36,
          padding: theme.spacing(1, 2)
        },
        input: {
          height: 48,
          boxSizing: 'border-box',
          padding: theme.spacing(2),
          '&::placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled
          },
          '&:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 1000px ${theme.palette.common.white} inset !important`,
            WebkitTextFillColor: `${theme.palette.text.primary} !important`
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottomColor: theme.palette.grey[300]
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[300]
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.action.disabledBackground
            }
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1
            }
          }
        }
      }
    }
  };
}
