/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Page to create styles for loader component.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 25/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

export default {
  rootStyle: () => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 1
  })
};
