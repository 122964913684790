/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Page to create routing from all the routes.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 25/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

/* Relative Imports */
import Loader from 'components/Loader';

/* Local Imports */
import ROUTE_ARRAY from './routeArray';

// ----------------------------------------------------------------------

/**
 * Create routing with the routes
 *
 * @return {JSX.Element}
 */
function Routing() {
  const content = useRoutes(ROUTE_ARRAY);
  return <Suspense fallback={<Loader />}>{content}</Suspense>;
}

export default Routing;
