/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Page to define the font family and styles based.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 24/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Relative Imports */
import { pxToRem } from 'utility/formatFontSize';

// ----------------------------------------------------------------------

/**
 * To set fontsize according to the width of the screen.
 *
 * @param sm - small font size
 * @param md - medium font size
 * @param lg - large font size
 * @returns font size based on the width of the screen
 */
function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:768px)': {
      fontSize: pxToRem(sm)
    },
    '@media (min-width:1024px)': {
      fontSize: pxToRem(md)
    },
    '@media (min-width:1440px)': {
      fontSize: pxToRem(lg)
    }
  };
}

const typography = {
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 1.25,
    fontSize: pxToRem(40),
    textTransform: 'uppercase',
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 })
    // fontSize: pxToRem(32),
    // ...responsiveFontSizes({ sm: 38, md: 42, lg: 48 })
  },
  h2: {
    fontWeight: 700,
    lineHeight: 1.25,
    textTransform: 'uppercase',
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 38, md: 42, lg: 48 })
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1,
    textTransform: 'uppercase',
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 36, md: 40, lg: 40 })
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.32,
    fontSize: pxToRem(22),
    ...responsiveFontSizes({ sm: 24, md: 26, lg: 32 })
  },
  h5: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 22, lg: 24 })
  },
  h6: {
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 18, md: 20, lg: 22 })
  },
  subtitle1: {
    lineHeight: 1.5,
    fontWeight: 700,
    fontSize: pxToRem(16)
  },
  subtitle2: {
    lineHeight: 22 / 14,
    fontWeight: 700,
    fontSize: pxToRem(14)
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16)
  },
  body2: {
    lineHeight: 1.5,
    fontSize: pxToRem(14)
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12)
  }
};

export default typography;
