/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Page to create user guard.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 25/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

/* Relative Imports */
import SessionContext from 'context/SessionContext';
import { PAGES } from 'routes/paths';
import {
  ricaTrainingDreamTeamAgentUrl,
  ricaTrainingDreamTeamUrl
} from 'config/config';

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {node} children
 */
const propTypes = {
  children: PropTypes.element.isRequired
};

// ----------------------------------------------------------------------

/**
 * Component to define protectation layout for pages, which are not accessible after login
 *
 * @component
 * @param {node} children - contains the child components
 * @returns {JSX.Element}
 */
function UserGuard({ children }) {
  /* Hooks */
  const context = useContext(SessionContext);

  /* Output */
  if (context.isAuthenticated && context.user) {
    let redirectUrl = PAGES.ricaTraining.absolutePath;
    let state = null;

    if (context.promoterAgentAccess || context.agentAccess1) {
      redirectUrl = PAGES.registerSIM.absolutePath;
    } else {
      if (context.agentAccess2) {
        state = ricaTrainingDreamTeamAgentUrl;
      } else {
        state = ricaTrainingDreamTeamUrl;
      }
    }
    return <Navigate to={redirectUrl} state={state} />;
  }

  return children;
}

UserGuard.propTypes = propTypes;
export default UserGuard;
