/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description display loading screen during loading the page.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 24/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';

/* local Imports */
import styles from './index.style';

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {number} size
 */
const propTypes = {
  size: PropTypes.number
};

// ----------------------------------------------------------------------

/**
 * Component displays animated loading screen.
 *
 * @component
 * @param {number} size - size of the loader
 */
function Loader({ size = 40, ...other }) {
  /* Output */
  return (
    <Box data-testid="loader" sx={styles.rootStyle}>
      <CircularProgress size={size} {...other} />
    </Box>
  );
}

Loader.propTypes = propTypes;
export default memo(Loader);
