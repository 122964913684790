/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Page to create layout for pages.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 26/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

/* Local Imports */
import styles from './index.style';

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {node} children
 */
const propTypes = {
  children: PropTypes.node
};

// ----------------------------------------------------------------------

/**
 * Outer design layout for all pages
 *
 * @component
 * @param {node} children - contains the child components
 */
function AppLayout({ children }) {
  /* Output */
  return (
    <Box sx={styles.wrapperStyle}>
      <Box sx={styles.mainStyle}>{children}</Box>
    </Box>
  );
}

AppLayout.propTypes = propTypes;
export default AppLayout;
