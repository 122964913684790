/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Button component to override default Mui Button's style.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 24/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/**
 * Button contains the styles to override default Mui Button and it's children's styles.
 *
 * @component
 * @param theme - global theme object to provide colors, fonts, spacing, shadows etc.
 * @returns Add-on styles for MuiButton
 */
export default function Button(theme) {
  /* Output */
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          ...theme.typography.subtitle2,
          boxShadow: theme.customShadows.z1,
          borderRadius: 30,
          textAlign: 'center',
          padding: theme.spacing(0.5, 3),
          '&:hover': {
            boxShadow: theme.customShadows.z1,
            border: `1.5px solid ${theme.palette.primary.main}`,
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black
          }
        },
        containedSizeLarge: {
          height: 52
        },
        containedSizeMedium: {
          height: 46
        },
        containedSizeSmall: {
          height: 36
        },
        outlinedSizeLarge: {
          height: 52
        },
        outlinedSizeMedium: {
          height: 46
        },
        outlinedSizeSmall: {
          height: 36
        },
        text: {
          padding: 0,
          [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0
          },
          '&:hover': {
            background: 'none'
          }
        }
      }
    }
  };
}
