/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Divider component to override default Mui Divider's style.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 24/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/**
 * Divider contains the styles to override default Mui Divider and it's children's styles.
 *
 * @component
 * @param theme - global theme object to provide colors, fonts, spacing, shadows etc.
 * @returns Add-on styles for MuiDivider
 */
export default function Divider() {
  /* Output */

  return {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderBottomWidth: 1.5
        }
      }
    }
  };
}
