/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Page to define the root routes.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 25/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { lazy } from 'react';

/* Relative Imports */
import { AppLayout, IFrameLayout } from 'layout';

/* Local Imports */
import { PAGES } from './paths';
import AuthGuard from './guards/AuthGuard';
import UserGuard from './guards/UserGuard';

// ----------------------------------------------------------------------

/* Pages Imports */
const SignInPage = lazy(() => import('views/sign-in'));
const RegisterSIMPage = lazy(() => import('views/register-sim'));
const DreamTeamPage = lazy(() => import('views/dream-team'));
const RicaPage = lazy(() => import('views/rica'));
const RicaPortPage = lazy(() => import('views/rica'));
const RegisterAgentPage = lazy(() => import('views/register-agent'));
const ResetPasswordPage = lazy(() => import('views/reset-password'));

// ----------------------------------------------------------------------

/**
 * assign components to routes
 *
 * @return {array}
 */
const ROUTE_ARRAY = [
  {
    path: PAGES.root,
    element: (
      <IFrameLayout>
        <UserGuard>
          <SignInPage />
        </UserGuard>
      </IFrameLayout>
    )
  },
  {
    path: PAGES.registerSIM.relativePath,
    element: (
      <IFrameLayout>
        <AuthGuard>
          <RegisterSIMPage />
        </AuthGuard>
      </IFrameLayout>
    )
  },
  {
    path: PAGES.dreamTeam.relativePath,
    element: (
      <IFrameLayout>
        <DreamTeamPage />
      </IFrameLayout>
    )
  },
  {
    path: PAGES.ricaTraining.relativePath,
    element: (
      <IFrameLayout>
        <AuthGuard>
          <DreamTeamPage />
        </AuthGuard>
      </IFrameLayout>
    )
  },
  {
    path: PAGES.rica.relativePath,
    element: (
      <AppLayout>
        <AuthGuard>
          <RicaPage />
        </AuthGuard>
      </AppLayout>
    )
  },
  {
    path: PAGES.ricaPort.relativePath,
    element: (
      <AppLayout>
        <AuthGuard>
          <RicaPortPage />
        </AuthGuard>
      </AppLayout>
    )
  },
  {
    path: PAGES.registerAgent.relativePath,
    element: (
      <AppLayout>
        <AuthGuard>
          <RegisterAgentPage />
        </AuthGuard>
      </AppLayout>
    )
  },
  {
    path: PAGES.resetPassword.relativePath,
    element: (
      <AppLayout>
        <AuthGuard>
          <ResetPasswordPage />
        </AuthGuard>
      </AppLayout>
    )
  }
];

export default ROUTE_ARRAY;
