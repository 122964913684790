/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description LoadingButton component to override default Mui LoadingButton's style.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 24/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/**
 * LoadingButton contains the styles to override default Mui LoadingButton and it's children's styles.
 *
 * @component
 * @returns Add-on styles for MuiLoadingButton
 */
export default function LoadingButton(theme) {
  /* Output */
  return {
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            border: `1.5px solid ${theme.palette.primary.main}`,
            backgroundColor: theme.palette.common.white
          }
        },
        loadingIndicator: {
          color: theme.palette.primary.main
        }
      }
    }
  };
}
