/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Page to create notistack provider(snackbar) component.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 25/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { memo } from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
import { Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';

/* Local Imports */
import styles from './index.style';

// ----------------------------------------------------------------------

/**
 * @typedef snackbarProps
 * @property {object} icon
 * @property {string} color
 */
const snackbarIconPropTypes = {
  icon: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired
};

// ----------------------------------------------------------------------

/* Styles */
/**
 * styling icon container for snackbar
 * @param {object} icon - icon for snackbar
 * @param {string} color - color for icon container
 */
function SnackbarIcon({ icon, color }) {
  const IconSnackbar = icon;
  return (
    <Box
      component="span"
      sx={{
        ...styles.iconBox,
        color: `${color}.main`
      }}
    >
      <IconSnackbar width={24} height={24} />
    </Box>
  );
}

// ----------------------------------------------------------------------
/**
 * @typedef notistackProps
 *
 * @property {node} children
 */
const propTypes = {
  children: PropTypes.node.isRequired
};

/**
 * Shows message for a brief moment at right-top corner
 * @param {node} children - contains data to be displayed.
 *
 * @component
 */
function NotistackProvider({ children }) {
  /* Output */
  return (
    <Box sx={styles.rootStyle}>
      <SnackbarProvider
        dense
        maxSnack={5}
        preventDuplicate
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        iconVariant={{
          success: <SnackbarIcon icon={CheckCircleIcon} color="success" />,
          error: <SnackbarIcon icon={CancelIcon} color="error" />,
          warning: <SnackbarIcon icon={ErrorIcon} color="warning" />,
          info: <SnackbarIcon icon={InfoIcon} color="info" />
        }}
      >
        {children}
      </SnackbarProvider>
    </Box>
  );
}

SnackbarIcon.propTypes = snackbarIconPropTypes;
NotistackProvider.propTypes = propTypes;
export default memo(NotistackProvider);
