/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Page to define the configrations for project.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 26/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

export const apiBaseUrl = process.env.REACT_APP_API_BASEURL;
export const apiAccessToken = process.env.REACT_APP_API_ACCESSTOKEN;
export const netreadyApiUrl = process.env.REACT_APP_NETREADY_API_URL;
export const dreamTeamUrl = process.env.REACT_APP_DREAM_TEAM_URL;
export const externalApiKey = process.env.REACT_APP_EXTERNAL_API_KEY;
export const ricaAgentFormFieldId =
  process.env.REACT_APP_RICA_AGENT_FORM_FIELD_ID;
export const promoterAccessCardId = process.env.REACT_APP_PROMOTER_ACCESS_CARD_ID;
export const accessCardId = process.env.REACT_APP_ACCESS_CARD_ID;
export const accessCardId2 = process.env.REACT_APP_ACCESS_CARD_ID_2;

export const ericaDreamTeamUrl = process.env.REACT_APP_ERICA_DREAM_TEAM_URL;
export const ericaDreamTeamHomeUrl = `${ericaDreamTeamUrl}/home?a=f1e0`;
export const ericaDreamTeamRicaCompleteUrl = `${ericaDreamTeamUrl}/rica-complete`;

export const ricaTrainingDreamTeamUrl =
  process.env.REACT_APP_RICA_TRAINING_DREAM_TEAM_URL;
export const ricaTrainingSignInUrl = `${ricaTrainingDreamTeamUrl}/download-app`;
export const ricaTrainingDreamTeamUploadUrl = `${ricaTrainingDreamTeamUrl}/upload-documents`;
export const ricaTrainingDreamTeamAgentUrl = `${ricaTrainingDreamTeamUrl}/register-as-agent`;
export const ricaTrainingSetPasswordUrl = `${ricaTrainingDreamTeamUrl}/rica-password`;
