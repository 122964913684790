/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Page to define helper functions for authentication.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 25/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Constants */
const cookieKey = 'cellc_rica_token';

// ----------------------------------------------------------------------
/**
 * function to set user object in cookies
 *
 * @param {string} user - logged in user object
 * @returns {void}
 */
export const setUserCookies = (user) => {
  sessionStorage.setItem(cookieKey, JSON.stringify(user));
};

/**
 * function to remove user object from cookies
 *
 * @returns {void}
 */
export const removeUserCookies = () => {
  sessionStorage.removeItem(cookieKey);
};

/**
 * function to get user object from cookies
 *
 * @returns {string} - returns a user object from cookies
 */
export const getUserCookies = () => {
  const user = sessionStorage.getItem(cookieKey);
  return user ? JSON.parse(user) : null;
};
