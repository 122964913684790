/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Page to define the Border Radius
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 24/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Constants */
const shape = {
  borderRadiusXs: 4,
  borderRadius: 8,
  borderRadiusSm: 12,
  borderRadiusMd: 16
};

export default shape;
