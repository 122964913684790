/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Page to create auth guard.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 25/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

/* Relative Imports */
import { PAGES } from 'routes/paths';
import SessionContext from 'context/SessionContext';

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {node} children
 */
const propTypes = {
  children: PropTypes.element.isRequired
};

// ----------------------------------------------------------------------

/**
 * Component to define protectation layout for pages, which are not accessible without login
 *
 * @component
 * @param {node} children - contains the child components
 * @returns {JSX.Element}
 */
function AuthGuard({ children }) {
  /* Hooks */
  const context = useContext(SessionContext);

  /* Output */
  if (!context.isAuthenticated) {
    return <Navigate to={PAGES.root} />;
  }

  return children;
}

AuthGuard.propTypes = propTypes;
export default AuthGuard;
