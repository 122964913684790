/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Theme Config is used to set themes to its children components.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 24/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  createTheme,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider
} from '@mui/material';
import '@mui/lab/themeAugmentation';

/* Local Imports */
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import palette from './palette';
import shadows, { customShadows } from './shadows';
import shape from './shape';
import typography from './typography';

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {node} children
 */
const propTypes = {
  children: PropTypes.node
};

/**
 * Theme Config is used to set themes to its children components
 *
 * @param children - nested components to set the theme
 * @returns provides theme to its children
 */
function ThemeConfig({ children }) {
  const themeOptions = useMemo(
    () => ({
      palette: { ...palette, mode: 'dark' },
      shape,
      typography,
      breakpoints,
      direction: 'ltr',
      shadows,
      customShadows
    }),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  /* Output */
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

ThemeConfig.propTypes = propTypes;
export default ThemeConfig;
