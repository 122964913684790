/**
 * @copyright @2024 Dream Team Cell C. All rights reserved.
 * @description Page to define the session context.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 29/Apr/2024
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import React from 'react';
import PropTypes from 'prop-types';

/* Imports */
import {
  getUserCookies,
  removeUserCookies,
  setUserCookies
} from 'helper/authHelper';

// ----------------------------------------------------------------------

/* Initial State */
const initialState = {
  isAuthenticated: false,
  user: null,
  promoterAgentAccess: false,
  agentAccess1: false,
  agentAccess2: false,
  formField: null,
  LoginUser: async () => {},
  LogoutUser: () => {}
};

/* Create Context */
const SessionContext = React.createContext(initialState);

// ----------------------------------------------------------------------
/**
 * @typedef propTypes
 * @property {node} children
 */
const propTypes = {
  children: PropTypes.node.isRequired
};

// ----------------------------------------------------------------------

/**
 * Component to define session provider
 *
 * @component
 * @param {node} children - contains the child components
 * @returns {JSX.Element}
 */
class Session extends React.Component {
  /* Constructor */
  constructor(props) {
    super(props);

    const userCookies = getUserCookies();
    this.state = {
      isAuthenticated: Boolean(userCookies),
      user: userCookies ? userCookies.user : null,
      promoterAgentAccess: userCookies
        ? userCookies.promoterAgentAccess
        : false,
      agentAccess1: userCookies ? userCookies.agentAccess1 : false,
      agentAccess2: userCookies ? userCookies.agentAccess2 : false,
      formField: userCookies ? userCookies.formField : null,
      LoginUser: (user, promoterAgentAccess, agentAccess1, agentAccess2, formField) => {
        setUserCookies({
          user,
          promoterAgentAccess,
          agentAccess1,
          agentAccess2,
          formField
        });
        this.setState({
          isAuthenticated: true,
          user,
          promoterAgentAccess,
          agentAccess1,
          agentAccess2,
          formField
        });
      },
      LogoutUser: () => {
        removeUserCookies();
        this.setState((prevState) => ({
          ...prevState,
          isAuthenticated: false,
          user: null,
          promoterAgentAccess: false,
          agentAccess1: false,
          agentAccess2: false,
          formField: null
        }));
      }
    };
  }

  /* Output */
  render() {
    return (
      <SessionContext.Provider value={this.state}>
        {this.props.children}
      </SessionContext.Provider>
    );
  }
}

Session.propTypes = propTypes;
export default SessionContext;
export const SessionProvider = Session;
export const SessionConsumer = SessionContext.Consumer;
